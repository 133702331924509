// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { Div, svgSprite } from '../../../hybrid/wrappers';
import { admin } from '../../../project/uikit/color-variables';
import { t } from '../../../common/components/T';

export const transformVolumes = (
    group_volume,
    total_group_volume,
    turnover_volume,
    total_turnover_volume,
    volume_left,
    volume_right,
    small_branch,
    classic_volume_left,
    classic_volume_right,
        qualification_volume_by_structure_volume,
    qualification_volume_by_classic_volume,
    trans
) => {
    return [
        {
            alias: 'user-coin',
            volume: turnover_volume?.formatted_value || '0 PV',
        title: turnover_volume.name

},
    {
        alias: 'user-coin',
            volume: total_turnover_volume?.formatted_value || '0 PV',
        title: total_turnover_volume.name

    },
        {
            alias: 'coins',
            volume: group_volume?.formatted_value || '0 PV',
            title: group_volume.name

        },
        {
            alias: 'coins',
            volume: total_group_volume?.formatted_value || '0 PV',
            title: total_group_volume.name

        },

        {
            alias: 'structure-1-2',
            volume: volume_left?.formatted_value || 'PV 0',
            title: volume_left?.name

        },
        {
            alias: 'structure-2-2',
            volume: volume_right?.formatted_value || 'PV 0',
            title: volume_right?.name

        },
        {
            alias: 'structure-1-2',
            volume: classic_volume_left?.formatted_value || 'PV 0',
            title: classic_volume_left?.name

        },
        {
            alias: 'structure-2-2',
            volume: classic_volume_right?.formatted_value || 'PV 0',
            title: classic_volume_right?.name

        },
        {
            alias: 'user',
            volume: small_branch?.formatted_value || 'PV 0',
            title: small_branch?.name
        },
        {
            alias: 'user',
            volume: qualification_volume_by_structure_volume?.formatted_value || 'PV 0',
            title: qualification_volume_by_structure_volume?.name
        },
        {
            alias: 'user',
            volume: qualification_volume_by_classic_volume?.formatted_value || 'PV 0',
            title: qualification_volume_by_classic_volume?.name
        },

    ]
}
const UserVolumes = ({
    styles, data, trans
}) => {
    const volumes = transformVolumes(
        data?.marketing_profile?.group_volume,
        data?.marketing_profile?.total_group_volume,
        data?.marketing_profile?.turnover_volume,
        data?.marketing_profile?.total_turnover_volume,
        data?.marketing_profile?.volume_left,
        data?.marketing_profile?.volume_right,
        data?.marketing_profile?.small_branch,
        data?.marketing_profile?.classic_volume_left,
        data?.marketing_profile?.classic_volume_right,
        data?.marketing_profile?.qualification_volume_by_structure_volume,
        data?.marketing_profile?.qualification_volume_by_classic_volume,
        trans
    )
    return (
        volumes.map((volumeItem, idx) =>
            <Div styles={styles.userModalVolumeItem} key={`userInfo${idx}`}>
                <Div styles={{ ...styles.userModalInfoIcon, 'background-color': admin[`officeVolumesColorGroupTransparent_${idx}`] }}>
                    {
                        svgSprite(volumeItem.alias, {
                            fill: admin[`officeVolumesColorGroup_${idx}`],
                            width: '20px',
                            height: '20px'
                        })
                    }
                </Div>
                <Div>
                    <Div styles={styles.userModalVolumeValue}>{volumeItem.volume}</Div>
                    <Div styles={styles.userModalVolumeTitle}>{volumeItem.title}</Div>
                </Div>
            </Div>)
    )
};
UserVolumes.propTypes = {
    styles: PropTypes.object,
    volumes: PropTypes.array
};
export default UserVolumes;